import {
    NavigateFunction,
    Params,
    useLocation,
    useNavigate, useParams,
    useSearchParams
} from "react-router-dom";

export type RouterMetadata = {
    location: Location,
    navigate: NavigateFunction,
    params: Readonly<Params<string>>
    queryString: URLSearchParams
}

export default function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const [queryString, _] = useSearchParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params, queryString }}
            />
        );
    }

    return ComponentWithRouterProp;
}