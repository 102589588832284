const SESSION_API_KEY = 'KF_CANDIDATE_SESSION_API_KEY';
const SESSION_CANDIDATE_ID = 'KF_CANDIDATE_ID';
const CLOCK_OFFSET = 'KF_CLOCK_OFFSET';
const SESSION_PLATFORM = "KF_PLATFORM";

/**
 * Creates a new session
 * @param apiKey The API key to use for all authenticated server calls. Required in order to get/update data on the server
 */
export const createSession = (apiKey: string) => {
    sessionStorage.setItem(SESSION_API_KEY, apiKey);
    if (apiKey) { // Don't try and parse the key if we are clearing it
        setClockOffset();
    }
};

/**
 * Calculates the clock offset between client and server based on the issued time of the JWT and the current local time
 * NOTE: This must only ever be called immediately after the JWT has been received, any delay will introduce inaccurate
 * results
 * This will ignore any offsets less than 2s as below this level, network latency is likely to be more significant than
 * the actual offset between the clocks. We only really need accuracy down to 5-10s as it's used for providing warnings
 * to the user that their session is about to time out and to redirect the user to the login screen after it has expired.
 * The offset is stored in session storage for later use
 */
const setClockOffset = () => {
    const details = getSessionDetails();

    let offset: number = Date.now() - details.issued.getTime();
    // ignore offsets of < 2 s
    offset = offset > 2000 ? offset : 0;

    sessionStorage.setItem(CLOCK_OFFSET, offset.toString());
};

/**
 * Return the offset in ms between the clocks on the client and the server. This will have been calculated earlier from
 * the JWT when it was received.
 * This is retrieved from the session storage
 */
export const getClockOffset = (): number => {
    const offsetStr = sessionStorage.getItem(CLOCK_OFFSET);

    if (!offsetStr) {
        return 0;
    }

    return parseInt(offsetStr, 10);
};

/**
 * Returns the details of the current session taken from the JWT stored in the session storage
 */
export const getSessionDetails = () => {
    // Get the jwt from session storage
    const jwt = sessionStorage.getItem(SESSION_API_KEY);
    if (!jwt) {
        throw 'JWT not found';
    }

    // Split into its 3 parts
    const jwtParts = jwt.split('.');
    if (!jwtParts || jwtParts.length !== 3) {
        throw 'JWT not correctly formatted';
    }

    // Decode / parse the payload
    const payload: JWTPayload = JSON.parse(atob(jwtParts[1]));

    if (!payload) {
        throw 'JWT json could not be parsed';
    }

    const expiry: Date = new Date(payload.exp * 1000);

    const issued: Date = new Date(payload.iat * 1000);
    const timeoutMinutes: number = Math.round((payload.exp - payload.iat) / 60);
    const platform  = payload.Platform;

    return { expiry: expiry, issued: issued, timeoutMinutes: timeoutMinutes, platform: platform};
};

/**
 * Adds logged in  candidate Id to session, currently this is used by logger to add logged in candidate id to log messages. 
 * @param candidateId 
 */
export const storeCandidateId = (candidateId: number) => sessionStorage.setItem(SESSION_CANDIDATE_ID, candidateId.toString());

/**
 * Gets the logged in candidate id  from session. 
 * @param candidateId 
 */
export const getCandidateId = () => sessionStorage.getItem(SESSION_CANDIDATE_ID);

/**
 * Determine if there has been a local session created
 * @returns True if this user has been authenticated and can use this app
 */
export const hasSession = (): boolean => sessionStorage.getItem(SESSION_API_KEY) !== null;

/**
 * Gets the API key to use for all server commuications
 * @returns The API key to include in all HTTP requests
 * @see AppService
 */
export const getApiKey = (): string => sessionStorage.getItem(SESSION_API_KEY)!;

/**
 * Clears all the items in session storage
 */
export const clearSession = (): void => sessionStorage.clear();

/**
 * Interface for the structure of a JWT payload
 */
interface JWTPayload {
    UserId: string;

    // Not valid before
    nbf: number;

    // Expiry time
    exp: number;

    // Issue time
    iat: number;

    // Issuer
    iss: string;

    // Audience (what the token is intended for) - URL
    aud: string;

    Platform?: string 
}

// Sets Platform key in session storage if platform key is present in JWT Key
export const setSessionPlatform = (): void => {
  const { platform } = getSessionDetails();
  if (platform) {
    sessionStorage.setItem(SESSION_PLATFORM, platform);
  }
};

// Gets the Platform key in the session storage to determine the platform where the candidate is coming from.
export const getSessionPlatform = (): string | null => {
  return sessionStorage.getItem(SESSION_PLATFORM);
};

// Clear candidate data from the session storage
export const clearCandidateDataFromSessionStorage = () => {
  sessionStorage.removeItem(SESSION_API_KEY);
  sessionStorage.removeItem(SESSION_CANDIDATE_ID);
  sessionStorage.removeItem(CLOCK_OFFSET);
};