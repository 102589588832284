import { Loading } from '../components/common/loading/loading';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { authorize } from '../services/authorize-service';
import { ResponseError } from '../services/api-service';
import { DevErrorView } from '../components/common/dev-error-view';
import { createSession } from '../misc/session';
import { RedirectToSource } from '../misc/common';
import { RouterMetadata } from '../components/higher-order/with-router';

/**
 * Props for the AuthenticatePage, values passed in via React Router
 */
type AuthenticatePageProps = {
    /**
     * The token string passed in from the KF signin app
     */
    token: string,

    /**
     * Privacy Policy. Whether or not to accept the privacy policy
     */
    pp: boolean,

    /**
     * Privacy Policy Custom Checkbox Accepted. Provided if candidate had to accept privacy policy at sign in
     */
    ppcca: boolean | null,

    /**
     * React Router meta data such as location, params, navigation
     */
    router: RouterMetadata
};

/**
 * Enum constants to define the different states the AuthenticatePage component can be in.
 */
enum AuthenticationState {
    /** The component is current sending/awaiting the authorization request */
    Authorizing,

    /** Authorization succeeded and an API key was returned */
    ApiKeyReceived,

    /** Authorization failed and an error was returned */
    ErrorReturned
}

/**
 * Local UI state for the AuthenticatePage component
 */
type AuthenticatePageState = {
    /**
     * The current local state this component is in as part of the process flow
     */
    currentState: AuthenticationState,

    /**
     * The API key returned by the server if successful.
     */
    apiKey?: string,

    /**
     * The error response returned by the server if unsuccessful.
     */
    errorResponse?: ResponseError,

    /**
     * Determine if we are in development mode, used to decide how to handle errors
     */
    inDevMode: boolean
};

/**
 * Renders an Authentication view - a loading screen initially whilst the server is contacted to authenticate the token and get
 * a working session.
 */
export default class AuthenticatePage extends React.Component<AuthenticatePageProps, AuthenticatePageState> {
    constructor(props: AuthenticatePageProps) {
        super(props);
        this.state = {
            currentState: AuthenticationState.Authorizing,
            inDevMode: process.env.NODE_ENV === `development`
        };
    }

    /**
     * Lifecycle event that occurs when the component has finished rendering.
     * 
     * After rendering the initial loading splash screen, authenticate the user using the provided token and create a new session to continue with
     * using the dashboard.
     */
    componentDidMount() {
        if (this.state.currentState === AuthenticationState.Authorizing) {
            this.authorize();
        }
    }

    /**
     * Lifecycle event that occurs when the component has updated state.
     * @param prevProps The previous props object
     * @param prevState The previous state object
     * @param prevContext The previous context
     */
    /* tslint:disable-next-line:no-any */
    componentDidUpdate(prevProps: Readonly<AuthenticatePageProps>, prevState: Readonly<AuthenticatePageState>, prevContext: any) {
        if (this.state.currentState === AuthenticationState.ErrorReturned && !this.state.inDevMode) {
            // Kick back out to the KF signin app or session timeout page if user platform is IC2
            RedirectToSource();
        }
    }

    render() {
        if (this.state.currentState === AuthenticationState.ApiKeyReceived) {
            return <Navigate to="/" />;
        }

        if (this.state.currentState === AuthenticationState.ErrorReturned) {
            return this.renderError();
        }

        return <Loading />;
    }

    private authorize() {
        const { params, queryString } = this.props.router;

        // KF Signin and Legacy TQ assessments will pass the token in the URL, but the Reflex assessments will pass it as a query string parameter
        let token = params.token;
        if (!token || token.length == 0) {
            token = queryString.get('token')!;
        }

        authorize(token!)
            .then(apiKey => {
                createSession(apiKey);
                this.authorizeComplete(apiKey);
            })
            .catch((error: ResponseError) => {
                this.setState({
                    currentState: AuthenticationState.ErrorReturned,
                    errorResponse: error
                });
            });

    }

    private authorizeComplete(apiKey: string) {
        this.setState({
            currentState: AuthenticationState.ApiKeyReceived,
            apiKey
        });
    }

    private renderError() {
        if (this.state.inDevMode) {
            return <DevErrorView message="An error occurred during authorization" errorResponse={this.state.errorResponse} loginUrl={process.env.REACT_APP_SIGNIN_URL} />;
        }

        // For a nicer view for redirecting out and back to the KF signin page, just show the loading spinner again. Redirecting
        // will actually occur in the componentDidUpdate lifecycle event
        return <Loading />;
    }
}