import React from "react";
import ReactModal from "react-modal";
import * as Containers from "../redux-containers";
import { EmptyObject } from "../../misc/common";
import { CmsService } from "../../services/cms-service";
import { Actions } from "../../actions/dynamicFeedbackOverlay";

interface DynamicFeedbackOverlayStateProps {
    isOpen: boolean;
    allText: any;
    languageId: number;
    dynamicFeedbackLink: string | undefined;
}

interface DynamicFeedbackOverlayDispatchProps {
    close: () => void;
    open: () => void;
}

export type DynamicFeedbackOverlayProps = DynamicFeedbackOverlayStateProps &
    DynamicFeedbackOverlayDispatchProps;

class DynamicFeedbackOverlay extends React.Component<
    DynamicFeedbackOverlayProps,
    EmptyObject
> {

    constructor(props: DynamicFeedbackOverlayProps) {
        super(props);
    }

    public componentDidMount() {
        ReactModal.setAppElement("body");
    }

    public render() {
        const isOpen: boolean = !!this.props.isOpen;
        const dynamicFeedbackLaunchURL = this.props.dynamicFeedbackLink

        return (
            <ReactModal
                isOpen={isOpen}
                className={`${CmsService.GetSiteFontStyle()} dynamic-feedback-overlay`}
                overlayClassName="modal-overlay"
                ariaHideApp={false}
            >
                <div className="modal-body">
                    <button type="button" className="btn-close" aria-label="Close" onClick={this.handleClose}></button>
                    <iframe
                        src={dynamicFeedbackLaunchURL}
                        title="Dynamic Feedback Report"
                    ></iframe>
                </div>
            </ReactModal>
        );
    }

    private handleClose = () => {
        this.props.close();
    }
}

export default Containers.createStateAndDispatchWithProps<
    DynamicFeedbackOverlayStateProps,
    DynamicFeedbackOverlayDispatchProps,
    EmptyObject
>(
    DynamicFeedbackOverlay,
    (state) => ({
        isOpen: state.dynamicFeedback.showDynamicFeedbackOverlay,
        dynamicFeedbackLink: state.dynamicFeedback.dynamicFeedbackLink,
        allText: state.language.alltext,
        languageId: state.language.languageId,
    }),
    (dispatch) => ({
        close: () => dispatch<any>(Actions.showDynamicFeedbackOverlay({ show: false })),
        open: () => dispatch<any>(Actions.showDynamicFeedbackOverlay({ show: true })),
    })
);
