import { getBlobAndDownload, postObject } from './api-service';

/**
 * Gets a candidate report and downloads it to the client.
 * @param id The ID of the project (hub) or assessment (legacy) to get the report for
 */
export const getReport = (id: number, reportType: string = '', reportLanguage: number = 0, reportOptions?: string):
    Promise<Blob> => getBlobAndDownload('report/' + id, reportType ? { reportType, reportLanguage, reportOptions } : null);

export interface DFRRequest {
    projectId: number;
    reportLocale: string;
    reportType: string
}

export const launchDynamicFeedback = (
    projectId: number,
    reportType: string = "",
    reportLocale: string = "en-US"
) => {
    
    const params = { projectId, reportType, reportLocale };
    return postObject<DFRRequest, string>(
        "dfr/launch",
        params,
        (result) => <string>result
    );
};