import { BrandingResult }  from '../models/branding';

const SESSION_BRANDING_KEY = 'KF_BRANDING';

export const storeBranding = (branding: BrandingResult) => sessionStorage.setItem(SESSION_BRANDING_KEY, JSON.stringify(branding));

export const hasBranding = (): boolean => {
    var brandingAvailable: boolean = false;
    try {
        if (sessionStorage.getItem(SESSION_BRANDING_KEY) !== null) {
            JSON.parse(sessionStorage.getItem(SESSION_BRANDING_KEY)!);
            brandingAvailable = true;
        }
    } catch (e) {
        brandingAvailable = false;
        clearBrandingSession();
    }
    return brandingAvailable;
};

export const getBranding = (): BrandingResult => JSON.parse(sessionStorage.getItem(SESSION_BRANDING_KEY)!);

export const clearBrandingSession = (): void => sessionStorage.removeItem(SESSION_BRANDING_KEY);