/**
 * Helper types, methods for dealing with actions.
 */

/**
 * Action follows the flux standard action, used to fire actions from the components to trigger 
 * updates in the state via the reducers. Generics used to allow each action to define custom typed 
 * data, accessible in the reducers.
 * @type T - The type of the action, used in the reducers to separate the actions
 * @type P - The type of the payload data, allows the payload to be strongly typed.
 * @field error - May be set to true if the action contains errors.
 */
export interface Action<T, P> {
    readonly type: T;
    readonly payload: P;
    error?: boolean;
    meta?: {};
}

/**
 * Generic help method for creating a new Action object.
 * @param type - The type of action firing
 * @param payload - The data included in the action, this is typed based on the action.
 */
export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return { type, payload };
}