import { getApiKey, hasSession, clearSession } from '../misc/session';
import 'isomorphic-fetch';
import * as queryString from 'query-string';
import * as statusCode from 'http-status';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

/**
 * Represents options to control a request to the server.
 */
export interface RequestOptions {
    /**
     * Have every request include authorization headers.
     */
    authorize: boolean;
}

const DefaultRequestOptions: RequestOptions = {
    authorize: true
};

/**
 * Creates the full url using the environment variables of the api method to call.
 * Note: The action name might already be the full url, so just pass through as is, 
 * this would be the case for the CMS, to allow it to use a different url for the cloudfront CDN.
 * @param action The api action name to build the url with.
 */
export function buildActionUrl(action: string): string {

    // Absolute path, no need to build with the existing configuration.
    if (action.substr(0, 4).toLowerCase() === 'http') {
        return action;
    }

    return buildActionUrlFromParts(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_VERSION, action);
}

/**
 * Builds a complete url with the parts required for the api call. e.g. http://api.site.com/v1.0/action
 * @param apiUrlBase Base http path for the url
 * @param apiVersionNumber The version to use for the api
 * @param action The action name
 */
export function buildActionUrlFromParts(apiUrlBase: string, apiVersionNumber: string, action: string) {
    return `${apiUrlBase}v${apiVersionNumber}/${action}`;
}

/**
 * Send a GET request with an object as the body and convert the result into another object.
 * @param action The API route to call
 * @param converter A function that accepts JSON (any data) and converts it to the required type.
 * @param params Optional parameters to include in the query string for the request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @returns A Promise containing the result
 */
export function getObject<TInput, TOutput>(
    action: string,
    converter: (json: {}) => TOutput,
    params?: TInput,
    options: RequestOptions = DefaultRequestOptions)
    : Promise<TOutput> {
    return get(action, params as object, options)
        .then(response => converter(response.data));
}

/**
 * Send a GET request with an object as the body and return the Blob result, then automatically save it
 * to the client machine.
 * @param action The API route to call
 * @param params Optional parameters to include in the query string for the request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @returns A Promise containing the Blob result saved
 */
export function getBlobAndDownload<TInput>(
    action: string,
    params?: TInput,
    options: RequestOptions = DefaultRequestOptions)
: Promise<Blob> {
    return fetch(makeGetUrl(action, params as object), {
        headers: createHeaders(options),
        method: 'GET'
    })
        .then(parseResponseAsBlob)
        .then(handleResponse)
        .then(downloadBlobToClient);
}

/**
 * Send a POST request with an object as the body and convert the result into another object.
 * @param action The API route to call
 * @param input The object that should be put into the body of the request
 * @param converter A function that accepts JSON (any data) and converts it to the required type.
 * @param options Optional options to control the request. If not provided defaults will be used
 * @return A Promise containing the result
 */
export function postObject<TInput, TOutput>(
    action: string,
    input: TInput,
    converter: (json: {}) => TOutput,
    options: RequestOptions = DefaultRequestOptions)
    : Promise<TOutput> {
    return post(action, JSON.stringify(input), options)
        .then(response => converter(response.data));
}

/**
 * Send a POST request as text and return the result as JSON.
 * @param action The API route to call
 * @param body The body of the POST request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @return A Promise containing the result
 */
export function postText(
    action: string,
    body: string,
    options: RequestOptions = DefaultRequestOptions)
    : Promise<string> {
    return post(action, JSON.stringify(body), options)
        .then(response => response.data);
}

/**
 * Send a PUT request as text and return the result as JSON.
 * @param action The API route to call
 * @param body The body of the PUT request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @return A Promise containing the result
 */
export function putText(
    action: string,
    body: string,
    options: RequestOptions = DefaultRequestOptions)
    : Promise<string> {
    return put(action, JSON.stringify(body), options)
        .then(response => response.data);
}

/**
 * Send a DELETE request with an object as the body and convert the result into another object.
 * @param action The API route to call
 * @param params Optional parameters to include in the query string for the request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @returns A Promise containing the result
 */
export function deleteObject<TInput, TOutput>(
    action: string,
    params?: TInput,
    options: RequestOptions = DefaultRequestOptions)
    : Promise<TOutput> {
    return deleteRequest(action, params as object, options)
        .then(response => response.data);
}

/**
 * Makes a full GET url to use.
 * @param action The relative API route to call.
 * @param params Optional parameters to include in the query string for the request
 * @returns A full URL to call to the server
 */
export function makeGetUrl(action: string, params?: object): string {
    let url = buildActionUrl(action);
    if (params) {
        url += '?' + queryString.stringify(params);
    }
    return url;
}

/**
 * Send a GET request and handler the response.
 * @param action The relative API route to call.
 * @param params Optional parameters to include in the query string for the request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @returns A Promise containing the response wrapped in a ResponseData object
 * 
 * Because fetch() will always return a response even if the status was 400+ then we have to handle the response ourselves and decide
 * whether to resolve/reject the promise. This means first extracting the relevant response data into a IResponseData object and
 * then deciding what to do. If the response did not return OK then a IResponseError object will be used to reject the Promise, meaning this
 * is what should be caught to handle that error case.
 */
function get(action: string, params?: object, options: RequestOptions = DefaultRequestOptions): Promise<ResponseData> {
    return fetch(makeGetUrl(action, params), {
        headers: createHeaders(options),
        method: 'GET'
    })
        .then(parseResponseAsJson)
        .then(handleResponse);
}

/**
 * Send a POST request and handle the response
 * @param action The relative API route to call
 * @param body The body of the POST request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @return A Promise containing the response wrapped in a ResponseData object
 * 
 * Because fetch() will always return a response even if the status was 400+ then we have to handle the response ourselves and decide
 * whether to resolve/reject the promise. This means first extracting the relevant response data into a IResponseData object and
 * then deciding what to do. If the response did not return OK then a IResponseError object will be used to reject the Promise, meaning this
 * is what should be caught to handle that error case.
 */
function post(action: string, body: string, options: RequestOptions = DefaultRequestOptions): Promise<ResponseData> {
    return fetch(buildActionUrl(action), {
        headers: createHeaders(options),
        method: 'POST',
        body: body
    })
        .then(parseResponseAsJson)
        .then(handleResponse);
}

function put(action: string, body: string, options: RequestOptions = DefaultRequestOptions): Promise<ResponseData> {
    return fetch(buildActionUrl(action), {
        headers: createHeaders(options),
        method: 'PUT',
        body: body
    })
        .then(parseResponseAsJson)
        .then(handleResponse);
}

/**
 * Send a DELETE request and handler the response.
 * @param action The relative API route to call.
 * @param params Optional parameters to include in the query string for the request
 * @param options Optional options to control the request. If not provided defaults will be used
 * @returns A Promise containing the response wrapped in a ResponseData object
 * 
 * Because fetch() will always return a response even if the status was 400+ then we have to handle the response ourselves and decide
 * whether to resolve/reject the promise. This means first extracting the relevant response data into a IResponseData object and
 * then deciding what to do. If the response did not return OK then a IResponseError object will be used to reject the Promise, meaning this
 * is what should be caught to handle that error case.
 */
function deleteRequest(action: string, params?: object, options: RequestOptions = DefaultRequestOptions): Promise<ResponseData> {
    return fetch(makeGetUrl(action, params), {
        headers: createHeaders(options),
        method: 'DELETE'
    })
        .then((response) => {
            if (!response.ok) {
                return parseResponseAsJson(response);
            } else {
                return Promise.resolve({
                    status: response.status,
                    ok: response.ok,
                    headers: response.headers,
                    data: { message: null }
                });
            }
        })
        .then(handleResponse);
}

function createHeaders(options: RequestOptions = DefaultRequestOptions): Headers {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (options.authorize && hasSession()) {
        headers.append('Authorization', 'Bearer ' + getApiKey());
    }
    return headers;
}

/**
 * Represents the details of a response that returned a non-successful HTTP code.
 */
export interface ResponseError {
    /**
     * The HTTP status code of the response.
     */
    status: number;

    /**
     * The message contained in the response.
     */
    message: string;
}

/**
 * Represents the data contained in the response.
 */
interface ResponseData {
    /**
     * The HTTP status code of the response.
     */
    readonly status: number;

    /**
     * Determines whether the response was successful.
     */
    readonly ok: boolean;

    /**
     * The HTTP headers contained in the response.
     */
    readonly headers: Headers;

    /**
     * The data returned in the response.
     */
    /* tslint:disable-next-line:no-any */
    readonly data: any;
}

/**
 * Parses a response to extract useful JSON information out and return a IResponseData object instead.
 * @param response The HTTP response to parse.
 * @returns The HTTP response to manage within the specific function handler
 */
function parseResponseAsJson(response: Response): Promise<ResponseData> {
    return new Promise(resolve => response
        .json()
        .then(json => resolve({
            status: response.status,
            ok: response.ok,
            headers: response.headers,
            data: json
        })));
}

/**
 * Parses a response to extract useful Blob information out and return a IResponseData object instead.
 * @param response The HTTP response to parse.
 * @returns The HTTP response to manage within the specific function handler
 */
function parseResponseAsBlob(response: Response): Promise<ResponseData> {
    return new Promise(resolve => response
        .blob()
        .then(blob => resolve({
            status: response.status,
            ok: response.ok,
            headers: response.headers,
            data: blob
        })));
}

/**
 * Handles a response to either continue or reject the promise.
 * @param response A ResponseData object containing the relevant details.
 * @returns The HTTP response to manage within the specific function handler
 */
function handleResponse(response: ResponseData): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
        if (response.status === statusCode.UNAVAILABLE_FOR_LEGAL_REASONS) {
            window.location.pathname = '/sanctioned';
            clearSession();
            return reject({
                status: response.status,
                message: response.data.message
            });
        }
        if (!response.ok) {
            return reject({
                status: response.status,
                message: response.data.message
            });
        }
        return resolve(response);
    });
}

/**
 * Handles a Blob response by triggering the client browser to download and save a Blob to file.
 * @param response A ResponseData object containing the relevant details.
 * @returns The Blob object saved
 * 
 * This is kind of a hack to get the browser to do what we intend. The Blob was retrieved via a manual HTTP request because
 * we had to include the authentication header, but now the file won't automatically save to the client machine. To get around
 * this you do what this says: https://stackoverflow.com/a/43133108/982487
 * 
 * 1. Make a temp anchor element
 * 2. Make a tmep object URL to the blob
 * 3. Assign the object URL to the anchor and click it, this make the Save As dialog popup
 * 4. Cleanup afterwards. The anchor is parentless so will be cleaned up by the GC later
 */
function downloadBlobToClient(response: ResponseData): Promise<Blob> {
    let fileName = 'download.tmp';
    if (response.headers.has('Content-Disposition')) {
        fileName = getContentDispositionFileName(response.headers.get('Content-Disposition')!);
    }

    if (navigator.msSaveBlob) { // IE10+
        navigator.msSaveBlob(response.data, fileName);
    } else {
        let objectUrl = window.URL.createObjectURL(response.data);
        let anchor = document.createElement('a');
        anchor.href = objectUrl;
        anchor.download = decodeURI(fileName);
        document.body.appendChild(anchor);
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
    }
    return Promise.resolve(response.data);
}

function getContentDispositionFileName(header: string): string {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = regex.exec(header);
    if (matches !== null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
    }

    return 'download.tmp';
}