import * as State from './state';
import { Actions } from '../actions/dynamicFeedbackOverlay';

/**
 * The default initial state
 */
export const dynamicFeedbackInitial: State.DynamicFeedback = {
    showDynamicFeedbackOverlay: false,
    dynamicFeedbackLink: undefined
};

/**
 * Reducer for the RedirectWarning
 */
export const DynamicFeedbackReducer =
    (state: State.DynamicFeedback = dynamicFeedbackInitial, action: Actions.DynamicFeedbackShowAction): State.DynamicFeedback => {
        switch (action.type) {
            case Actions.DynamicFeedbackShowType:
                return {
                    ...state,
                    showDynamicFeedbackOverlay: action.payload.show.valueOf(),
                    dynamicFeedbackLink: action.payload.dynamicFeedbackLaunchURL?.valueOf()
                };
            default:
                return state;
        }
    };