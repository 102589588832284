import React from 'react';
import {  NoJsxElement, NullableElement } from '../../misc/common';
import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import { DispatchProp } from 'react-redux';
import DownloadReport from './downloadReport';
import { ReportModel } from '../../models/report';
import SuccessProfileNotPublishedMessage from './successProfileNotPublishedMessage';

export interface DownloadReportsStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * All assessments for candidate
     */
    reports?: ReportModel[];
}

type DownloadReportsAllProps = DownloadReportsStateProps & DispatchProp<any>;

/**
 * Renders a download reports component that downloads the reports from the server.
 * @param props The properties to pass to the component
 */
export class DownloadReports extends React.Component<DownloadReportsAllProps> {

    render(): NullableElement {
        const {reports, text} = this.props;

        if (!reports || reports.length === 0) {
            return NoJsxElement;
        }

        const groupReports = (reports: ReportModel[]) => {
            const groupedReports = reports.reduce((acc: { [key: number]: ReportModel[] }, report) => {
                const key = report.parentBlendedReportId ?? report.blendedReportId;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(report);
                acc[key].sort((a, b) => (a.parentBlendedReportId ?? 0) - (b.parentBlendedReportId ?? 0));
                return acc;
            }, {});
            return groupedReports;
        }

        return (
            <div className="download-reports-container">
                <h2 className="download-reports-heading">
                    { text.get('reportsAvailable', 'Available Reports') }
                </h2>
                <div className="reports-wrapper">
                    {
                        Object.values(groupReports(reports)).map((r) => {
                            return <DownloadReport reports={r} key={r[0].projectId + r[0].reportType + Number(r[0].date) + r[0].languageId + r[0].reportOptions} />;
                        })
                    }
                </div>
                <SuccessProfileNotPublishedMessage /> 
            </div>
        );
    }
}

export default Containers.createStateOnly<DownloadReportsStateProps>(
    DownloadReports,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        reports: state.candidate.reports
    })
);