import { Action, createAction } from './actions';


interface DynamicFeedbackActionPayload {
    show: boolean;
    dynamicFeedbackLaunchURL?: string;
}

export namespace Actions {
    export const DynamicFeedbackShowType = 'DYNAMIC_FEEDBACK_OVERLAY_SHOW';
    export type DynamicFeedbackShowAction = Action<typeof DynamicFeedbackShowType, DynamicFeedbackActionPayload>;
    export function showDynamicFeedbackOverlay(payload: DynamicFeedbackActionPayload): DynamicFeedbackShowAction {
        return createAction(DynamicFeedbackShowType, payload);
    }
}